import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postTopLoaderFalse, postTopLoaderTrue, saveQuestionDetails, setSnackbar, } from '../../store/actions';
import { useStyle } from '../../styles/style';
import EditorCK from '../BaseComponents/EditorCK';
const QuestionEditorModal = ({ open, handleClose, exam, examSet, questions, }) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [value, setValue] = useState('<p>#Question:</p><p>#Answer:</p>');
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [questionAnswerArray, setQuestionAnswerArray] = useState([]);
    const editorOnChange = (editor) => {
        setValue(editor);
    };
    const handleOnClick = () => {
        var _a, _b;
        if ((((_a = value.match('<p><br></p>')) === null || _a === void 0 ? void 0 : _a.length) && value.length === 11) ||
            value.length === 0 ||
            ((_b = value.match('<p>#Question:</p><p>#Answer:</p>')) === null || _b === void 0 ? void 0 : _b.length)) {
            dispatch(setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMsg: 'Please enter some data to upload.',
            }));
            return;
        }
        const questionAnswer = [];
        const questionsMatches = [...value.matchAll(/#Question:/g)];
        const answerMatches = [...value.matchAll(/#Answer:/g)];
        if (questionsMatches.length !== answerMatches.length) {
            dispatch(setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMsg: `Number of questions ${questionsMatches.length} does not Number of answers ${answerMatches.length}.
          Please check the data and upload again.`,
            }));
            return;
        }
        questionsMatches.forEach((questionMatch, index) => {
            var _a, _b, _c, _d;
            const answerMatch = answerMatches === null || answerMatches === void 0 ? void 0 : answerMatches[index];
            const question = value.slice(questionMatch.index +
                value
                    .slice(questionMatch.index)
                    .indexOf((_b = (_a = value
                    .slice(questionMatch.index)
                    .match(/<[\w\d\s=:;&,"-]+>/g)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : ''), answerMatch.index);
            let answer = value.slice(answerMatch.index + 8, (_d = (_c = questionsMatches === null || questionsMatches === void 0 ? void 0 : questionsMatches[index + 1]) === null || _c === void 0 ? void 0 : _c.index) !== null && _d !== void 0 ? _d : value.length);
            if (answer.length === 0) {
                answer = value.slice(answerMatch.index + 9, value.lastIndexOf('<'));
            }
            questionAnswer.push({ question, answer });
        });
        if (questionAnswer.length > exam.noOfQuestionsPerSet - questions.length) {
            dispatch(setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMsg: `Data is more than expected. ${exam.noOfQuestionsPerSet - questions.length} questions are expected. But you are trying to upload ${questionAnswer.length} questions.`,
            }));
            return;
        }
        setQuestionAnswerArray(questionAnswer);
        setConfirmationModal(true);
    };
    const handleConfirmationButton = () => {
        dispatch(postTopLoaderTrue());
        questionAnswerArray.forEach((questionAnswer) => {
            const commonPayload = {
                activeStatus: true,
                createdBy: user.emailId,
                modifiedBy: user.emailId,
            };
            const payload = Object.assign(Object.assign({ questionDescription: questionAnswer.question, correctMark: exam.correctAnswerMark, negativeMark: exam.negativeAnswerMark, questionHint: '' }, commonPayload), { examSetID: examSet.examSetID, choices: [Object.assign({ choice: questionAnswer.answer }, commonPayload)] });
            dispatch(saveQuestionDetails(payload));
        });
        dispatch(postTopLoaderFalse());
        setConfirmationModal(false);
        handleClose();
        dispatch(setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Data uploaded successfully.',
        }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: open, onClose: handleClose, disableScrollLock: true },
            React.createElement(Box, null,
                React.createElement("div", { className: classes.questionExcel },
                    React.createElement("div", { className: classes.questionPreviewMain },
                        React.createElement("div", { className: classes.questionExcelHeader },
                            React.createElement(Typography, { variant: "h3" }, "Upload Question Using Editor"),
                            React.createElement("div", { className: classes.closeIcon },
                                React.createElement(IconButton, { onClick: handleClose },
                                    React.createElement(Close, null)))),
                        React.createElement("div", { className: classes.questionExcelBody, style: { width: '60rem', minHeight: '25rem' } },
                            React.createElement(Typography, null, "Please follow below format to upload multiple questions:"),
                            React.createElement(Typography, { variant: "h3", style: { marginLeft: '1rem' } },
                                "#Question:",
                                React.createElement("br", null),
                                "#Answer:"),
                            React.createElement(EditorCK, { placeholder: "#Question:<br/>#Answer:", disabled: false, errors: undefined, onChange: editorOnChange, value: value, height: "25rem" })),
                        React.createElement(Button, { onClick: handleOnClick, fullWidth: true, variant: "contained" }, "Upload Data"))))),
        confirmationModal && (React.createElement(Modal, { open: confirmationModal, onClose: () => {
                setConfirmationModal(false);
            }, disableScrollLock: true },
            React.createElement(Box, null,
                React.createElement("div", { className: classes.questionExcel },
                    React.createElement("div", { className: classes.questionPreviewMain },
                        React.createElement("div", { className: classes.questionExcelHeader },
                            React.createElement(Typography, { variant: "h3" }, "Question Confirmation Modal"),
                            React.createElement("div", { className: classes.closeIcon },
                                React.createElement(IconButton, { onClick: () => setConfirmationModal(false) },
                                    React.createElement(Close, null)))),
                        React.createElement("div", { className: classes.questionExcelBody },
                            React.createElement(Typography, null,
                                "You have entered",
                                ' ',
                                React.createElement("strong", null, questionAnswerArray.length),
                                " questions. Please click below button to upload.")),
                        React.createElement(Button, { onClick: handleConfirmationButton, fullWidth: true, variant: "contained" }, "Confirm"))))))));
};
export default QuestionEditorModal;
