import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useStyle } from '../../styles/style';
import QuestionLearningExcelModal from '../ExamLearning/QuestionLearningExcelModal';
import QuestionExcelModal from './QuestionExcelModal';
const QuestionExcelButton = ({ exam, examSet, questions }) => {
    const classes = useStyle();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleButtonClick = () => {
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "contained", size: "small", onClick: handleButtonClick, disabled: questions.length === exam.noOfQuestionsPerSet }, "IMPORT USING EXCEL"),
        isModalOpen && examSet.examType !== 'LEARNING' && (React.createElement(QuestionExcelModal, { open: isModalOpen, handleClose: handleClose, exam: exam, examSet: examSet, questions: questions })),
        isModalOpen && examSet.examType === 'LEARNING' && (React.createElement(QuestionLearningExcelModal, { open: isModalOpen, handleClose: handleClose, exam: exam, examSet: examSet, questions: questions }))));
};
export default QuestionExcelButton;
