import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useStyle } from '../../styles/style';
import QuestionEditorModal from './QuestionEditorModal';
const QuestionEditorButton = ({ exam, examSet, questions }) => {
    const classes = useStyle();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleButtonClick = () => {
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { size: "small", variant: "outlined", onClick: handleButtonClick, disabled: questions.length === exam.noOfQuestionsPerSet }, "IMPORT USING EDITOR"),
        isModalOpen && (React.createElement(QuestionEditorModal, { open: isModalOpen, handleClose: handleClose, exam: exam, examSet: examSet, questions: questions }))));
};
export default QuestionEditorButton;
